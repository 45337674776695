import { shellGeistArr } from '../assets/shellGeistPhotos/shellGeistData';
import { polariArr } from '../assets/polariPhotos/polariData';
import { planetNineArr } from '../assets/planetNinePhotos/planetNineData';
// import { ecoMerchantArr } from '../assets/ecoMerchantPhotos/ecoMerchantData';
// import { gameJunkeeArr } from '../assets/gameJunkeePhotos/gameJunkeeData';
import { blehNmahArr } from '../assets/blehNmahPhotos/blehNmahData';
import { kalanchoeAiArr } from '../assets/kalanchoeAiPhotos/kalanchoeAiData';
import { maraudersArr } from '../assets/maraudersPhotos/maraudersData';
// import muvdArr from '../assets/muvdPhotos/muvd';
// import quizArr from '../assets/quizPhotos/quiz';
// import textEditorArr from '../assets/textEditorPhotos/textEditor';
// import weatherDashboardArr from '../assets/weatherDashboardPhotos/weatherDashboard';


export const ApplicationData = [
    { id: 8, applicationTitle: "Marauders", mainPhoto: "https://i.imgur.com/qRrBjGO.png", description: "Take advantage of tools to develop 3D and Augmented Reality objects. Add a device to control the tools while you're at it or add a device to keep track of things in your home.", applicationImage: maraudersArr, applicationLink: "https://marauders.vercel.app", github: "https://github.com/Blehjo/marauderclient" },
    { id: 7, applicationTitle: "Planet Nine", mainPhoto: "https://i.imgur.com/2aetrCK.png", description: "Become a space pioneer and chart your findings on potential planets. Use your A.I. crew to probe the galaxy for life, resources, and information.", applicationImage: planetNineArr, applicationLink: "https://planetnine.azurewebsites.net", github: "https://github.com/Blehjo/planetnine" },
    { id: 3, applicationTitle: "KalanchoeAI", mainPhoto: "https://i.imgur.com/PQgALzE.png", description: "KalanchoeAI is a site for developing projects and getting insights from communities and your handy AI.", applicationImage: kalanchoeAiArr, applicationLink: "https://kalanchoeai.azurewebsites.net/", github: "https://github.com/Blehjo/KalanchoeAI-Frontend" },
    { id: 1, applicationTitle: "Shell Geist", mainPhoto: "https://i.imgur.com/fRFjZvl.png", description: "Think of Shell Geist as a virtual meetup. You can attend events by getting a code and joining video game lobbies, joining live discussions, and leaving your own feed back from first-hand experience.", applicationImage: shellGeistArr, applicationLink: "https://shellgeist.com/", github: "https://github.com/Blehjo/shell-geist" },
    { id: 4, applicationTitle: "Bleh Nmah", mainPhoto: "https://i.imgur.com/ZqWiUnj.jpg", description: "Portfolio of my artwork. Users can view my collections and look into my paintings. Think of it as an online gallery. Enjoy!", applicationImage: blehNmahArr, applicationLink: "https://razorpagesblehnmah.azurewebsites.net/", github: "https://github.com/Blehjo/BlehNmah" },
    { id: 2, applicationTitle: "Polari", mainPhoto: "https://i.imgur.com/Fm0d6ya.png", description: "Polari is a dating website catered to the LGBTQ community with an emphasis to educate users of their attachment styles.", applicationImage: polariArr, applicationLink: "https://polari-attachments.herokuapp.com/", github: "https://github.com/polari-2022" },
    // { id: 4, applicationTitle: "Game Junkee", mainPhoto: "1tspNDCp079MMJei6Rk6wV66KwRtK3J1A", description: "With this project we wanted to create an application where users could post about games they love and other users could offer comments about the game and include recommendations about other games the original poster might enjoy.", applicationImage: gameJunkeeArr, applicationLink: "https://gamejunkee.herokuapp.com/", github: "https://github.com/Project-2-BJMS/GameJunkee" },
    // { id: 5, applicationTitle: "Eco Merchant", mainPhoto: "https://i.imgur.com/qbhKshA.png", description: "Eco Merchant is an ecommerce web application. You can browse clothing, add them to your cart, and purchase them. Navigate through the site seamlessly and even sign up, so that you can receive updates as the catalogue get's updates.", applicationImage: ecoMerchantArr, applicationLink: "https://exquisite-basbousa-208df9.netlify.app", github: "https://github.com/Blehjo/eco-merchant" },
    // { id: 11, applicationTitle: "Search engine", mainPhoto: "../assets/quizPhotos/quiz1.png", description: "This is a search engine for books. You are able to search for any book and read its description. If the book interests you, you can save it to your library as a reminder to look into it or read it. Once the book is no longer of interests to you, you can delete it from your library.", applicationImage: quizArr, applicationLink: "https://blehjo-mern-book-search.herokuapp.com/", github: "https://github.com/Blehjo/mern-search-engine/" },
    // { id: 4, applicationTitle: "Progressive Web Application", mainPhoto: "../assets/textEditorPhotos/texteditor1.png", description: "Website that I refactored with MERN stack. Allows the user to search for books and save it to his/her/their collection.", applicationImage: textEditorArr, applicationLink: "https://online-offline-editor.herokuapp.com/", github: "https://www.github.com/Blehjo/pwa" },
    // { id: 2, applicationTitle: "Muvd", mainPhoto: "../assets/muvdPhotos/muvd1.png", description: "Muvd is an application that allows users to search for movies, or suggests movies based on the user's mood. It then shows some basic information about the movie from The Movie Database, and if available, provides a link to a review of the movie from the New York Times.", applicationImage: muvdArr, applicationLink: "https://coding-p1-g1.github.io/muvd/", github: "https://github.com/coding-p1-g1/muvd" },
    // { id: 5, applicationTitle: "Weather Dashboard", mainPhoto: "../assets/weatherDashboardPhotos/weatherdashboard.png", description: "Weather dashboard where users can search for cities and get the cities current weather and five-day forecast. The cities are also saved to local storage, so the user can access past searches.", applicationImage: weatherDashboardArr, applicationLink: "https://blehjo.github.io/weather-dashboard/", github: "https://github.com/Blehjo/weather-dashboard" },
]