const shellGeist1 = "https://i.imgur.com/fRFjZvl.png";
const shellGeist2 = "https://i.imgur.com/SIjC5l8.jpg";
const shellGeist3 = "https://i.imgur.com/vTLxVP1.jpg";
const shellGeist4 = "https://i.imgur.com/A7fhD5s.png";
const shellGeist5 = "https://i.imgur.com/RnoDRdD.png";
const shellGeist6 = "https://i.imgur.com/LHOVKXh.png";
const shellGeist7 = "https://i.imgur.com/fGaZgtR.png";
const shellGeist8 = "https://i.imgur.com/pqlCGvL.png";

export const shellGeistArr = [
    shellGeist1,
    shellGeist2,
    shellGeist3,
    shellGeist4,
    shellGeist5,
    shellGeist6,
    shellGeist7,
    shellGeist8,
];