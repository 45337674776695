const kalanchoeAi1 = "https://i.imgur.com/U8X1MME.png";
const kalanchoeAi2 = "https://i.imgur.com/iLdnnuZ.png";
const kalanchoeAi3 = "https://i.imgur.com/nrqDcfT.png";
const kalanchoeAi4 = "https://i.imgur.com/uEfFyGL.png";
const kalanchoeAi5 = "https://i.imgur.com/U3RzGdu.png";
const kalanchoeAi6 = "https://i.imgur.com/oj36eVv.png";
const kalanchoeAi7 = "https://i.imgur.com/PQgALzE.png";
const kalanchoeAi8 = "https://i.imgur.com/cvQYmOU.png";

export const kalanchoeAiArr = [
    kalanchoeAi7,
    kalanchoeAi1,
    kalanchoeAi2,
    kalanchoeAi3,
    kalanchoeAi4,
    kalanchoeAi5,
    kalanchoeAi6,
    kalanchoeAi8,
];