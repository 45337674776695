const polari1 = "https://i.imgur.com/Fm0d6ya.png";
const polari2 = "https://i.imgur.com/QxrCgKa.png";
const polari3 = "https://i.imgur.com/Bw7wWIi.png";
const polari4 = "https://i.imgur.com/XSc0c2m.png";
const polari5 = "https://i.imgur.com/02beXxI.png";
const polari6 = "https://i.imgur.com/4LymwNZ.png";
const polari7 = "https://i.imgur.com/OSdQ7J7.png";
const polari8 = "https://i.imgur.com/wDUOD3G.png";
const polari9 = "https://i.imgur.com/rMg5MLM.png";


export const polariArr = [
    polari1,
    polari2,
    polari3,
    polari4,
    polari5,
    polari6,
    polari7,
    polari8,
    polari9,
];