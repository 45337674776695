const planetNine6 = "https://i.imgur.com/hAkba4b.png";
const planetNine2 = "https://i.imgur.com/5EHuFNU.png";
const planetNine3 = "https://i.imgur.com/eZYmAa8.png";
const planetNine4 = "https://i.imgur.com/ac9np4C.png";
const planetNine5 = "https://i.imgur.com/nQXmGW3.png";
const planetNine1 = "https://i.imgur.com/6BfgmfU.png";
const planetNine7 = "https://i.imgur.com/EchPL0x.png";


export const planetNineArr = [
    planetNine1,
    planetNine2,
    planetNine3,
    planetNine4,
    planetNine5,
    planetNine6,
    planetNine7
];