const marauder1 = "https://i.imgur.com/qRrBjGO.png";
const marauder2 = "https://i.imgur.com/q4PcA5i.png";
const marauder3 = "https://i.imgur.com/f20QUiq.png";
const marauder4 = "https://i.imgur.com/laZQTaC.png";
const marauder5 = "https://i.imgur.com/vhuDqWY.png";
const marauder6 = "https://i.imgur.com/puXaOS7.png";
const marauder7 = "https://i.imgur.com/a955mV1.png";
const marauder8 = "https://i.imgur.com/cvQYmOU.png";

export const maraudersArr = [
    marauder1,
    marauder2,
    marauder3,
    marauder4,
    marauder5,
    marauder6,
    marauder7,
    // marauder8,
];