const blehNmah1 = "https://i.imgur.com/ZqWiUnj.jpg";
const blehNmah2 = "https://i.imgur.com/x2nNSI1.jpg";
const blehNmah3 = "https://i.imgur.com/b7wjqp9.jpg";
const blehNmah4 = "https://i.imgur.com/Zo7bXql.jpg";
const blehNmah5 = "https://i.imgur.com/B0ZdhIG.jpg";

export const blehNmahArr = [
    blehNmah1,
    blehNmah2,
    blehNmah3,
    blehNmah4,
    blehNmah5,
];